import { rad2deg } from '@/utils/utils'
import { frequency } from 'units-converter'
import { FREQUENCY_DECIMALS } from '@/components/Dialogs/Filters/types'

export const formatFrequency = (val): string => {
  if (val === null) return null
  const freq = frequency(val)
    .from('Hz')
    .toBest()
  if (Number.isInteger(freq.value)) {
    return `${parseFloat(freq.value)} ${freq.unit}`
  } else {
    return `${parseFloat(freq.value.toFixed(FREQUENCY_DECIMALS))} ${freq.unit}`
  }
}

export const formatBearing = (val): string => {
  if (val === null) return val
  let bearing = rad2deg(val)
  if (bearing > 180) {
    bearing -= 360
  }
  return `${bearing.toFixed(0)}`
}

export const formatBearingTolerance = (val): string => {
  if (val === null) return null
  return `${rad2deg(val).toFixed(0)}`
}
