





















































import Vue from 'vue'
import { IRfFilter } from '@/store/modules/rf_filters/types'
import { ISentry } from '@/store/modules/sentries/types'
import { EItemTypes, ISupportedDetection } from './types'
import AdvancedFiltersTable from '@/components/Dialogs/Filters/AdvancedFiltersTable.vue'
import AdvancedFiltersOverview from '@/components/Dialogs/Filters/AdvancedFiltersOverview.vue'
import AdvancedFilterFormDialog from '@/components/Dialogs/Filters/FilterForm/AdvancedFilterFormDialog.vue'
import { mapGetters, mapState } from 'vuex'
import { ESiteMode } from '@/store/modules/sites/types'

const props = {
  type: {
    type: String,
    default: EItemTypes.FILTER,
    validator: val => {
      return Object.values(EItemTypes).includes(val)
    }
  },
  sensor: {
    type: Object,
    default: null
  },
  sentry: {
    type: Object as () => ISentry,
    default: null
  },
  disabledForAdd: {
    type: Boolean,
    default: false
  }
}

const BASE_RF_FILTER: IRfFilter = {
  active: true,
  azimuth: null,
  bearing: null,
  bearing_tolerance: null,
  created_at: null,
  frequency: null,
  frequency_tolerance: null,
  id: null,
  mac_address: null,
  name: null,
  protocol: null,
  rf_sensor_ids: [],
  updated_at: null,
  vendor: null,
  whitelist: false
}

export default Vue.extend({
  name: 'AdvancedFilters',
  props,
  provide() {
    const providedData = {}
    Object.defineProperty(providedData, 'sensor', {
      enumerable: true,
      get: () => this.sensor
    })

    Object.defineProperty(providedData, 'itemType', {
      enumerable: true,
      get: () => this.type
    })

    Object.defineProperty(providedData, 'supportedDetections', {
      enumerable: true,
      get: () => this.supportedDetections
    })

    return {
      providedData
    }
  },
  components: {
    AdvancedFilterFormDialog,
    AdvancedFiltersOverview,
    AdvancedFiltersTable
  },
  data() {
    return {
      showFilterAddForm: false,
      highlightedFilters: []
    }
  },
  computed: {
    ...mapGetters('sites', { activeSiteMode: 'activeSiteMode' }),
    ...mapState('rf_filters', {
      rfFilters: 'rf_filters' 
    }),
    allowReadWrite(): boolean {
      return this.activeSiteMode === ESiteMode.Calibration
    },
    defaultFilterData(): IRfFilter {
      const filter = { ...BASE_RF_FILTER }
      filter.rf_sensor_ids = [this.sensor.id]
      filter.whitelist = this.type === EItemTypes.WHITELIST
      filter.name = this.nextItemName
      return filter
    },
    nextItemName(): string {
      const data =
        this.type === EItemTypes.WHITELIST
          ? {
              prefix: 'Whitelist',
              suffix: this.onlyWhitelists.length + 1
            }
          : {
              prefix: 'Filter',
              suffix: this.onlyFilters.length + 1
            }
      return `${data.prefix} #${data.suffix}`
    },
    supportedDetections(): Record<string, ISupportedDetection> {
      let supportedDetections = {
        ...this.sensor?.supported_detections
      }
      delete supportedDetections['Multidrone']
      return supportedDetections
    },
    addItemTooltip(): string {
      return `Add ${this.type}`
    },
    onlyFilters(): IRfFilter[] {
      if (!this.rfFilters[this.sensor.id]) return [] as IRfFilter[]
      return Object.values(this.rfFilters[this.sensor.id]).filter((filter: IRfFilter) => !filter.whitelist) as IRfFilter[]
    },
    onlyWhitelists(): IRfFilter[] {
      if (!this.rfFilters[this.sensor.id]) return [] as IRfFilter[]
      return Object.values(this.rfFilters[this.sensor.id]).filter((filter: IRfFilter) => filter.whitelist) as IRfFilter[]
    },
    normalisedFilters(): IRfFilter[] {
      const item =
        this.type === EItemTypes.WHITELIST
          ? this.onlyWhitelists
          : this.onlyFilters
      return item.map(filter => {
        return { ...BASE_RF_FILTER, ...filter }
      })
    },
    enabledFilters(): IRfFilter[] {
      return this.normalisedFilters.filter(filter => filter.active)
    },
    showOverview(): boolean {
      return this.enabledFilters.length > 0
    },
    cleanSupportedDetections(): Record<string, ISupportedDetection> {
      const items = {}
      Object.keys(this.supportedDetections).forEach(key => {
        if (key.toUpperCase() !== 'MULTIDRONE') {
          items[key] = this.supportedDetections[key]
        }
      })
      return items
    }
  },
  methods: {
    addNewItem(): void {
      this.showFilterAddForm = true
    },
    cancelFormDialog(): void {
      this.showFilterAddForm = false
    },
    onHighlight({ id }) {
      if (this.highlightedFilters.includes(id)) {
        const index = this.highlightedFilters.findIndex(item => item === id)
        this.highlightedFilters.splice(index, 1)
      } else {
        this.highlightedFilters.push(id)
      }
    }
  }
})
