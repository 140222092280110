
















































































































































import Vue from 'vue'
import { IRfFilter } from '@/store/modules/rf_filters/types'
// @ts-ignore
import { frequency } from 'units-converter'
import { capitalize, rad2deg } from '@/utils/utils'
import {
  formatBearing,
  formatBearingTolerance,
  formatFrequency
} from '@/components/Dialogs/Filters/utils'
import { mapActions, mapGetters } from 'vuex'
import AdvancedFilterFormDialog from '@/components/Dialogs/Filters/FilterForm/AdvancedFilterFormDialog.vue'
import SimpleConfirmationDialog from '@/components/Dialogs/SimpleConfirmationDialog.vue'
import { EItemTypes } from '@/components/Dialogs/Filters/types'

const props = {
  filters: {
    type: Array as () => IRfFilter[],
    default: () => []
  },
  highlightedFilters: {
    type: Array as () => number[],
    default: () => []
  },
  allowReadWrite: {
    type: Boolean,
    default: false
  },
  disableForEdit: {
    type: Boolean,
    default: false
  }
}

interface ITableHeader {
  text: string
  value: string
  align?: string
  sortable?: boolean
  class?: string | string[]
  width?: string | number
}

const BASE_FILTER: IRfFilter = {
  active: false,
  bearing: null,
  bearing_tolerance: null,
  frequency: null,
  frequency_tolerance: null,
  id: -1000,
  name: null,
  protocol: null,
  vendor: null,
  whitelist: false,
  rf_sensor_ids: []
}

const USE_ANGLE_OF_ARRIVAL = true
export default Vue.extend({
  name: 'AdvancedFiltersTable',
  components: { SimpleConfirmationDialog, AdvancedFilterFormDialog },
  props,
  inject: ['providedData'],
  data() {
    return {
      filterBeingProcessed: null,
      deleteError: null,
      deleteInfo: null,
      activeFilter: null,
      activeFilterName: null,
      showFilterEditForm: false,
      showFilterDeleteDialog: false,
      apiCallInProgress: false
    }
  },
  computed: {
    ...mapGetters('sites', {
      activeSiteId: 'activeSiteId',
      activeSiteMode: 'activeSiteMode'
    }),
    sensor() {
      return this.providedData?.sensor || null
    },
    itemType(): EItemTypes {
      return this.providedData?.itemType || EItemTypes.FILTER
    },
    tableHeaders(): ITableHeader[] {
      const headers = [
        {
          text: 'Filter Name',
          value: 'name',
          sortable: true,
          width: '1%'
        },
        {
          text: 'Active',
          value: 'active',
          sortable: true,
          width: '1%'
        },
        {
          text: 'Vendor',
          value: 'vendor',
          sortable: true,
          width: '1%'
        },
        {
          text: 'Protocol',
          value: 'protocol',
          sortable: true,
          width: '1%'
        },
        {
          text: 'Frequency',
          value: 'frequency',
          sortable: true,
          width: '1%'
        },
        {
          text: 'Frequency Tolerance',
          value: 'name',
          sortable: true,
          width: '1%'
        },
        {
          text: 'Angle of Arrival',
          value: 'bearing',
          sortable: true,
          width: '1%'
        },
        {
          text: 'Angle Tolerance',
          value: 'bearing_tolerance',
          sortable: true,
          width: '1%'
        },
        {
          text: 'Highlight In Overview',
          value: 'highlight',
          sortable: false,
          width: '1%',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '1%',
          align: 'center'
        }
      ];

      const whiteListHeaders = headers.filter((header) => {
        const isSkippedInWhiteList = header?.value === 'bearing' || header?.value === 'bearing_tolerance';
        if (!isSkippedInWhiteList) return header
        return;
      })

      return this.isWhiteList ? whiteListHeaders : headers;
    },
    processedFilters(): IRfFilter[] {
      return this.filters.map(filter => {
        return { ...filter }
      })
    },
    isWhiteList(): boolean {
      return this.providedData?.itemType === EItemTypes.WHITELIST
    }
  },
  methods: {
    rad2deg,
    capitalize,
    formatFrequency,
    formatBearing,
    formatBearingTolerance,
    ...mapActions('rf_filters', {
      updateFilter: 'UPDATE_FILTER',
      fetchFilters: 'FETCH_RF_FILTERS',
      deleteFilter: 'DELETE_FILTER'
    }),
    getAngleValue(val): string {
      if (val === null) return null
      if (Number.isInteger(val)) {
        return `${val}°`
      } else {
        return `${val.toFixed(2)}°`
      }
    },
    getAngleToleranceValue(val): string {
      if (val === null) return null
      if (Number.isInteger(val)) {
        return `± ${val}°`
      } else {
        return `± ${val.toFixed(2)}°`
      }
    },
    cancelFormDialog(): void {
      this.showFilterEditForm = false
      this.activeFilter = null
    },
    triggerFilterEdit(filter: IRfFilter): void {
      this.activeFilter = filter
      this.showFilterEditForm = true
    },
    triggerFilterDelete(filter: IRfFilter): void {
      // using this for the name in the Delete Filter title otherwise when deleting it
      // we show null in between the filter is deleted and the timeout closes the delete dialog
      this.activeFilterName = filter.name
      this.activeFilter = filter
      this.showFilterDeleteDialog = true
    },
    async activeStateChanged(payload: IRfFilter): Promise<void> {
      this.filterBeingProcessed = payload.id
      await this.updateFilter({
        site_id: this.activeSiteId,
        id: payload.id,
        filter: payload
      })
      await this.fetchFilters({
        site_id: this.activeSiteId,
        sensor_id: this.sensor.id
      })
      setTimeout(() => {
        this.filterBeingProcessed = null
      }, 3000)
    },
    getTableText(val, isBearing = false, isTolerance = false) {
      if (val === null) return null
      return `${isTolerance ? '± ' : ''} ${val}${isBearing ? '°' : ''}`
    },
    highlightClicked(item): void {
      this.$emit('onHighlight', item)
    },
    cancelFilterDelete(): void {
      this.deleteInfo = null
      this.deleteError = null
      this.showFilterDeleteDialog = false
      this.activeFilter = null
    },
    async doDeleteFilter(): Promise<void> {
      this.apiCallInProgress = true
      const { success, error } = await this.deleteFilter({
        id: this.activeFilter.id,
        site_id: this.activeSiteId
      })

      if (success) {
        this.deleteInfo = `Successfully deleted ${this.itemType}`
        await this.fetchFilters({
          site_id: this.activeSiteId,
          sensor_id: this.sensor.id
        })
        setTimeout(() => {
          this.showFilterDeleteDialog = false
          this.deleteError = null
          this.deleteInfo = null
        }, 1000)
        this.activeFilter = null
      } else {
        let errorMessage = error
        if (this.itemType === EItemTypes.WHITELIST) {
          errorMessage = errorMessage
            .toLowerCase()
            .replace('rf filter', 'whitelist')
        }
        this.deleteError = errorMessage
      }
      this.$emitter.emit('update:dsx:wedge:data')
      this.apiCallInProgress = false
    }
  },
})
