









































































import Vue from 'vue'
import {
  IFilterSelectItem,
  ISupportedDetection,
  ISupportedDetectionProtocol
} from '@/components/Dialogs/Filters/types'
// @ts-ignore
import { frequency } from 'units-converter'
import { IRfFilter } from '@/store/modules/rf_filters/types'

const props = {
  disabled: {
    type: Boolean,
    default: true
  },
  filters: {
    type: Array as () => IRfFilter[],
    default: []
  }
}

const ONLY_USE_ENABLED_DETECTORS = false

export default Vue.extend({
  name: 'OverviewRefineDropdowns',
  props,
  inject: ['providedData'],
  data() {
    return {
      vendorFilter: [],
      protocolFilter: [],
      nameFilter: [],
      bandFilter: [],
      dropdownProps: {
        dense: true,
        multiple: true,
        clearable: false,
        hideDetails: true,
        singleLine: true,
        outline: true,
        itemValue: 'value',
        itemText: 'label',
        menuProps: {
          maxHeight: '100%'
        }
      }
    }
  },
  computed: {
    supportedDetections() {
      return this.providedData?.supportedDetections
    },
    supportedDetectionsToUse(): Record<string, ISupportedDetection> {
      return ONLY_USE_ENABLED_DETECTORS
        ? this.enabledSupportedDetections
        : this.supportedDetections
    },
    enabledSupportedDetections(): Record<string, ISupportedDetection> {
      const detections = {}
      Object.keys(this.supportedDetections).forEach((key: string) => {
        if (this.supportedDetections[key].enabled) {
          detections[key] = this.supportedDetections[key]
        }
      })
      return detections
    },
    vendors(): IFilterSelectItem[] {
      const protocols = []
      Object.values(this.supportedDetectionsToUse).forEach(
        (item: ISupportedDetection) => {
          item.protocols.forEach((protocol: ISupportedDetectionProtocol) => {
            protocols.push({
              label: protocol.vendor,
              value: protocol.vendor,
              band: item.rfai_sub_band
            })
          })
        }
      )
      return protocols.sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
      })
    },
    protocols(): IFilterSelectItem[] {
      const protocols = []
      Object.values(this.supportedDetectionsToUse).forEach(
        (item: ISupportedDetection) => {
          const vendors = this.vendorFilter.map(item => item.value)
          item.protocols.forEach((protocol: ISupportedDetectionProtocol) => {
            if (vendors.includes(protocol.vendor)) {
              const protocolName = protocols.findIndex(
                item => item.label === protocol.protocol
              )
              if (protocolName === -1) {
                protocols.push({
                  label: protocol.protocol,
                  value: protocol.protocol,
                  band: item.rfai_sub_band
                })
              }
            }
          })
        }
      )

      return protocols.sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
      })
    },
    names(): string[] {
      return this.filters.map(filter => filter.name) || []
    },
    bands(): IFilterSelectItem[] {
      return (
        Object.values(this.supportedDetectionsToUse).map(
          (item: ISupportedDetection) => ({
            label: this.getBandText(item.rfai_sub_band),
            value: item.rfai_sub_band
          })
        ) || []
      )
    }
  },
  methods: {
    getBandText(band): string {
      let unit = /[A-Za-z]+$/.exec(band)
      let freq = band.replace(unit, '')

      let f = frequency(freq)
        .from(unit)
        .toBest()

      return `${f.value} ${f.unit}`
    }
  },
  watch: {
    vendorFilter: {
      handler: function(val) {
        this.$emit('vendorFilter', val)
      }
    },
    protocolFilter: {
      handler: function(val) {
        this.$emit('protocolFilter', val)
      }
    },
    bandFilter: {
      handler: function(val) {
        this.$emit('bandFilter', val)
      }
    },
    nameFilter: {
      handler: function(val) {
        this.$emit('nameFilter', val)
      }
    }
  }
})
