
















































import Vue from 'vue'
import OverviewRefineDropdowns from '@/components/Dialogs/Filters/OverviewComponents/OverviewRefineDropdowns.vue'
import FrequencyFiltersOverview from '@/components/Dialogs/Filters/OverviewComponents/FrequencyFiltersOverview.vue'
import BearingFiltersOverview from '@/components/Dialogs/Filters/OverviewComponents/BearingFiltersOverview.vue'
import { IRfFilter } from '@/store/modules/rf_filters/types'
import {
  EItemTypes,
  FREQUENCY_LIMITS,
  IFilterSelectItem,
  ILimitItem
} from '@/components/Dialogs/Filters/types'
import { capitalize } from '@/utils/utils'

const props = {
  filters: {
    type: Array as () => IRfFilter[],
    default: []
  },
  highlighted: {
    type: Array as () => number[],
    default: () => []
  }
}

const CASE_SENSITIVE_FILTER = false

export default Vue.extend({
  name: 'AdvancedFiltersOverview',
  props,
  inject: ['providedData'],
  components: {
    BearingFiltersOverview,
    FrequencyFiltersOverview,
    OverviewRefineDropdowns
  },
  data() {
    return {
      nameFilters: [],
      bandFilters: [],
      vendorFilters: [],
      protocolFilters: [],
      overviewType: null
    }
  },
  mounted() {
    this.overviewType = this.overviewTypes[0].id
  },
  computed: {
    supportedDetections() {
      return this.providedData?.supportedDetections || []
    },
    itemType(): EItemTypes {
      return this.providedData?.itemType || EItemTypes.FILTER
    },
    overviewTypes() {
      return [
        {
          id: 'full',
          label: `Display all ${capitalize(this.itemType)}s`
        },
        {
          id: 'refined',
          label: 'Refine Overview by'
        }
      ]
    },
    showBearingOverview(): boolean {
      if (this.itemType === EItemTypes.WHITELIST) return false;
      return this.filtersWithBearing.length > 0
    },
    showFrequencyOverview(): boolean {
      return this.filtersWithFrequency.length > 0
    },
    dropdownDisabled(): boolean {
      return this.overviewType !== 'refined'
    },
    filtersWithBearing(): IRfFilter[] {
      return this.filteredFilters.filter(
        filter => filter.bearing || filter.bearing === 0
      )
    },
    filtersWithFrequency(): IRfFilter[] {
      return this.filteredFilters.filter(
        filter => filter.frequency || filter.frequency === 0
      )
    },
    filteredFilters(): IRfFilter[] {
      if (this.overviewType === 'full') return this.filters
      let filteredFilters = new Set([])

      // filter by band
      this.filters.forEach(filter => {
        // find band
        const band = Object.keys(FREQUENCY_LIMITS).find(key => {
          const limits: ILimitItem = FREQUENCY_LIMITS[key]
          return (
            filter.frequency >= limits.min && filter.frequency <= limits.max
          )
        })

        if (this.bandFilters.includes(`${band}MHz`)) {
          filteredFilters.add(filter)
        }

        this.nameFilters.forEach((name: string) => {
          if (name) {
            const nameFilter = CASE_SENSITIVE_FILTER ? name : name.toUpperCase()
            const nameValue = CASE_SENSITIVE_FILTER
              ? filter.name
              : filter.name.toUpperCase()

            if (nameFilter === nameValue) {
              filteredFilters.add(filter)
            }
          }
        })

        this.vendorFilters.forEach((vendor: IFilterSelectItem) => {
          if (filter.vendor) {
            const vendorFilter = CASE_SENSITIVE_FILTER
              ? vendor.value
              : vendor.value.toUpperCase()
            const vendorValue = CASE_SENSITIVE_FILTER
              ? filter.vendor
              : filter.vendor.toUpperCase()

            if (vendorFilter === vendorValue) {
              filteredFilters.add(filter)
            }
          }
        })

        this.protocolFilters.forEach((protocol: IFilterSelectItem) => {
          if (filter.protocol) {
            const protocolFilter = CASE_SENSITIVE_FILTER
              ? protocol.value
              : protocol.value.toUpperCase()
            const protocolValue = CASE_SENSITIVE_FILTER
              ? filter.protocol
              : filter.protocol.toUpperCase()

            if (protocolFilter === protocolValue) {
              filteredFilters.add(filter)
            }
          }
        })
      })

      return [...filteredFilters]
    }
  },
  methods: {
    onBandFilterUpdate(filters: string[]) {
      this.bandFilters = filters
    },
    onProtocolFilterUpdate(filters: string[]) {
      this.protocolFilters = filters
    },
    onVendorFilterUpdate(filters: string[]) {
      this.vendorFilters = filters
    },
    onNameFilterUpdate(filters: string[]) {
      this.nameFilters = filters
    }
  }
})
