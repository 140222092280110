<template>
  <base-dialog :dialog="dialog" @onClickClose="closeFiltersDialog">
    <template v-slot:header>
      <v-toolbar-title>RF Filters</v-toolbar-title>
    </template>
    <template v-slot:default>
      <v-layout row fill-height>
        <v-flex xs12 class="sensorForm px-2">
          <v-flex xs12 class="rf-filters__alerts">
            <sensor-alert :show="noSupportedDetections" />
            <browser-alert full-width :default-mode="ESiteMode.Calibration" />
          </v-flex>
          <v-tabs dark slider-color="primary" v-model="active">
            <v-tab v-for="(header, i) in headers" :key="i">{{ header }}</v-tab>
            <v-tab-item :key="0" class="elevation-0">
              <AdvancedFilters
                v-if="sensor && sentry"
                type="filter"
                :sensor="sensor"
                :sentry="sentry"
                :disabledForAdd="isDisabledForAddingFilters"
              />
            </v-tab-item>
            <v-tab-item>
              <AdvancedFilters
                v-if="sensor && sentry"
                type="whitelist"
                :sensor="sensor"
                :sentry="sentry"
                :disabledForAdd="isDisabledForAddingFilters"
              />
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </template>
  </base-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { BaseDialog } from '@/components/Base'
import { DialogMixin } from '@/components/Mixins'
import AdvancedFilters from '@/components/Dialogs/Filters/AdvancedFilters.vue'
import BrowserAlert from "@/components/Base/BrowserAlert.vue";
import SensorAlert from "@/components/Base/SensorAlert.vue";
import { ESiteMode } from '@/store/modules/sites/types'

export default {
  name: 'RfFiltersDialog',
  components: { SensorAlert, BrowserAlert, AdvancedFilters, BaseDialog },
  props: ['sensor', 'dialog', 'filter', 'sentry'],
  mixins: [DialogMixin],
  data: () => ({
    headers: ['RF Filters', 'RF Whitelists'],
    filterTmp: {},
    report: [],
    active: null
  }),
  provide() {
    return {
      editItemType: 'RF Filters'
    }
  },
  computed: {
    ESiteMode() {
      return ESiteMode
    },
    ...mapGetters('sites', ['activeSite', 'activeSiteId']),
    timezone() {
      return this.activeSite && this.activeSite.timezone
    },
    noSupportedDetections() {
      return !Boolean(Object.keys({...this.sensor?.supported_detections}).length)
    },
    isDisabledForAddingFilters() {
      const isCalibrationMode = this.activeSite.mode === 'calibration';
      if (!isCalibrationMode) {
        return true
      } else if(this.noSupportedDetections) {
        return true
      }
      return false;
    }
  },
  methods: {
    ...mapActions('rf_filters', {
      fetchRfFilters: 'FETCH_RF_FILTERS'
    }),
    closeFiltersDialog() {
      this.$bus.$emit('editEnd')
      this.$_onClickClose()
    },
    async fetchFilters() {
      if (!this.sensor?.id) return
      await this.fetchRfFilters({
        site_id: this.activeSiteId,
        sensor_id: this.sensor.id
      })
    }
  },
  watch: {
    dialog: {
      handler: async function() {
        if (!this.sensor?.id) return
        await this.fetchRfFilters({
          site_id: this.activeSiteId,
          sensor_id: this.sensor.id
        })
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
  .rf-filters__alerts {
    position: relative;
    padding-top: 4rem;
  }
</style>