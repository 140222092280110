

































import Vue from 'vue'
import { IRfFilter } from '@/store/modules/rf_filters/types'
import SliderComponent from '@/components/Dialogs/Filters/OverviewComponents/SliderComponent.vue'
import {
  EItemTypes,
  FREQUENCY_LIMITS
} from '@/components/Dialogs/Filters/types'
import { scaleValue } from '@/utils/utils'
// @ts-ignore
import { frequency } from 'units-converter'

const props = {
  filters: {
    type: Array as () => IRfFilter[],
    default: () => []
  },
  highlighted: {
    type: Array,
    default: () => []
  }
}

export default Vue.extend({
  name: 'FrequencyFiltersOverview',
  components: { SliderComponent },
  props,
  inject: ['providedData'],
  computed: {
    itemType(): EItemTypes {
      return this.providedData?.itemType || EItemTypes.FILTER
    },
    limits() {
      const limits = {}
      Object.keys(FREQUENCY_LIMITS).forEach(key => {
        limits[key] = {
          min: FREQUENCY_LIMITS[key].min,
          max: FREQUENCY_LIMITS[key].max
        }
      })
      return limits
    },
    wantedFilters(): IRfFilter[] {
      return this.filters.filter(filter => filter.frequency)
    },
    groupedFilters(): Record<number, IRfFilter[]> {
      const groups = {}
      Object.keys(FREQUENCY_LIMITS).forEach(limit => {
        const filters = this.wantedFilters.filter(
          filter =>
            filter.frequency >= FREQUENCY_LIMITS[limit].min &&
            filter.frequency <= FREQUENCY_LIMITS[limit].max
        )
        if (filters.length > 0) {
          groups[limit] = filters.map(filter => {
            return {
              id: filter.id,
              value: filter.frequency,
              tolerance: filter.frequency_tolerance
            }
          })
        }
      })
      return groups
    },
    sortedGroupKeys(): string[] {
      return Object.keys(this.groupedFilters).sort((a, b) => {
        if (a > b) return -1
        if (a < b) return 1
        return 0
      })
    }
  },
  methods: {
    getSliderTitle(val): string {
      const freq = frequency(val)
        .from('MHz')
        .toBest()
      return `${freq.value} ${freq.unit}`
    },
    getLineLabel(val): string {
      const freq = frequency(val)
        .from('MHz')
        .toBest()
      return `Frequency (${freq.unit})`
    },
    getMarks(min, max, count) {
      const step = 100 / count

      const markKeys = []
      for (let x = 0; x <= count; x++) {
        markKeys.push(x * step)
      }

      const marks = {}

      markKeys.forEach(key => {
        const val = scaleValue(key, 0, 100, min, max).toFixed(3)
        const freq = frequency(val)
          .from('Hz')
          .toBest()
        marks[key] = {
          label: `${freq.value}`,
          style: {
            display: 'block',
            zIndex: 10
          },
          labelStyle: {
            color: '#858585'
          }
        }
      })

      return marks
    }
  }
})
