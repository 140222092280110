










































import Vue from 'vue'
import { IRfFilter } from '@/store/modules/rf_filters/types'
import { rad2deg } from '@/utils/utils'
import SliderComponent, {
  ISliderItem
} from '@/components/Dialogs/Filters/OverviewComponents/SliderComponent.vue'
import { EItemTypes, ESliderTypes } from '@/components/Dialogs/Filters/types'

import WedgeBlock from '@/components/Dialogs/Filters/OverviewComponents/WedgeBlock.vue'
import AnglePreviewBlock from '@/components/Dialogs/Filters/OverviewComponents/AnglePreviewBlock.vue'

const props = {
  filters: {
    type: Array as () => IRfFilter[],
    default: () => []
  },
  highlighted: {
    type: Array,
    default: () => []
  }
}

const BEARING_LINE_BREAKPOINT = 950

export default Vue.extend({
  name: 'BearingFiltersOverview',
  components: { AnglePreviewBlock, WedgeBlock, SliderComponent },
  inject: ['providedData'],
  props,
  data() {
    return {
      sliderType: ESliderTypes.BEARING
    }
  },
  computed: {
    itemType(): EItemTypes {
      return this.providedData?.itemType || EItemTypes.FILTER
    },
    sensor() {
      return this.providedData?.sensor || null
    },
    bearingLineColumns(): number | string {
      return this.breakpoint.width < BEARING_LINE_BREAKPOINT ? 'xs12' : ''
    },
    breakpoint() {
      return this.$vuetify.breakpoint
    },
    isDSX(): boolean {
      return this.sensor.model.startsWith('dsx_')
    },
    filterBearingData() {
      return this.filters
        .filter(filter => {
          return ![null, undefined].includes(filter.bearing)
        })
        .map(filter => {
          return {
            id: filter.id,
            value: filter.bearing,
            tolerance: filter.bearing_tolerance
          }
        })
    }
  }
})
